/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'BabyDoll';
  src: local('BabyDoll'), url(./fonts/Baby\ Doll.ttf) format('truetype');
}

.BabyDoll {
  font-family: "BabyDoll";
 }
 /* end fonts */

:root {
  /* 1.65*16=700*(x/100)+y*16, 2*16 = 1400*(x/100) + y*16 */
  --fs-xl: clamp(1.65rem, 0.8vw + 1.3rem, 2rem);
}

.MainBackground {
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* Use intuitive block display for images */
img {
  display: block;
}

/*Remove default margin */ 
* {
  margin: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

body {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Standard stuff to apply to everything */
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  background-color: #000000;
}

/* Layout classes */
.MediumMarginTop {
  margin-top: max(1.25rem, 4%);
}

.MediumMarginSides {
  margin-inline: max(5%, 50% - 1275px/2);
}

.MediumMarginBottom {
  margin-bottom: 12%;
}

.ContentMediumMarginTop{
  margin-top: max(5%,2.5rem);
}

.ContentSmallMarginTop{
  margin-top: max(1.7%,1rem);
}

/*Text classes*/
.Uppercase{
  text-transform: uppercase;
}

.Yellow{
  color: #FFC41B;
}

.White{
  color: #ffffff;
}

.Black{
  color: #000000;
}

.Green {
  color: #55cf19;
}

.Red {
  color: #cf3819
}

.Normal{
  font-weight: normal;
}

.Light{
  font-weight: 200;
}

.Medium{
  font-weight: 500;
}

.Small{
  font-size: .727rem;
}

.ExtraLarge{
  font-size: var(--fs-xl);
}

.ExtraExtraLarge{
  /* 2*16=700*(x/100)+y*16, 2.5*16 = 1400*(x/100) + y*16 */
  font-size: clamp(2.2rem, 1.25714vw + 1.65rem, 2.75rem);
}

.Italic{
  font-style: italic;
}

.Left{
  text-align: left;
}

h1{
  font-size: var(--fs-xl);
}

h2{
  font-size: clamp(1.275rem, 0.514vw + 1.05rem, 1.5rem);
}

h3{
  font-size: clamp(1rem, 0.389vw + 0.83rem, 1.17rem);
}

/* Flex classes */

.FlexRowReverse{
  flex-direction: row-reverse;
}

/* utility classes */

.Hide {
  display: none !important;
}
/* Hero section */
.HeroSection{
  display: grid;
  grid-template-columns: [image-start] auto [image-end];
  grid-template-rows: auto [image-start] auto [image-end];
}

.SpaceBackgroundImage{
  width: 100%;
  min-height: 100%;
  height: 0;
  object-fit: cover;
  grid-area: image;
}

/* Introduction part */
.IntroductionPart{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: max(5%, 50% - 1140px/2);
  transform: translateX(38px);
}

.SigmaCatHoldingEarthVideo{
  width: min(800px,100%);
  margin-top: 2.75%;
}

.IntroductionTextPart{
  margin-top: -2.25%;
}

.TextPartLinks{
  display: flex;
  gap: 2.5rem;
}

.LargerTextMarginTop{
  margin-top: 1.35rem;
}

.TextPartLinks a {
  text-decoration: none;
  color: #fff;
}

.HeroSection::after{
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(28, 28, 28, 1.0), rgba(28, 28, 28, .4) 25%, rgba(28, 28, 28, .4));
  grid-area: image;
  z-index: 25;
}

.HeroSection::before{
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, rgba(28, 28, 28, 1.0), rgba(28, 28, 28, 0.0) 7%);
  grid-area: image;
  z-index: 100;
}

/* Explanation Part */
.ExplanationPart{
  display: flex;
  grid-area: image;
  margin-right: max(5%, 50% - 1275px/2);
  gap: 1.5rem;
  align-items: flex-end;
  margin-top: max(min(5.7%,85px),2.5rem);
  justify-content: flex-end;
}

.MountainCatsImage{
    width: 0;
    z-index: 50;
    height: 525px;
    max-width: 1034.143px;
    flex: 1;
    object-fit: cover;
    object-position: bottom right;
}

.ExplanationTextPart{
  margin-bottom: 1.1rem;
  /* 400=1170*(x/100)+y, 500 = 1400*(x/100) + y */
  width: clamp(400px, 43.478vw - 108.70px, 500px);
  z-index: 200;
}

.ExplanationTextPartLink{
  margin-left: 2.55ch;
}


@media (max-width:1170px) {
  .HeroSection {
    justify-items: center;
    align-items: center;
    grid-template-columns: [image-start] auto [image-end];
    grid-template-rows:  auto [image-start] auto auto [image-end] auto;
    grid-template-areas: 'video'
                         'introduction-text'
                         'mountain-image'
                         'explanation-text';
  }

  .ExplanationPart {
    display: contents;
  }

  .IntroductionPart {
    display: contents;
  }

  .IntroductionTextPart{
    grid-area: introduction-text;
    z-index: 1000;
    margin: 1.2rem 5% 0 5%;
  }

  .SigmaCatHoldingEarthVideo{
    margin: 0;
    grid-area: video;
  }

  .ExplanationTextPart{
    width: auto;
    grid-area: explanation-text;
    margin: 2.5rem 5% 0 5%;
  }

  .MountainCatsImage {
    margin-top: 2.5rem;
    width: 100%;
    min-height: 232px;
    height: auto;
    grid-area: mountain-image;
  }
}


/* Grindset Section */
.GrindsetSection{
  display: grid;
  align-items: center;
  grid-template-columns: [text-start] auto [image-start] auto [text-end] auto [image-end];
  grid-template-rows: [text-start] [image-start] auto [text-end] [image-end];
  margin-inline: max(5%, 50% - 1140px/2);
  margin-top: 2.5rem;
}

.GrindsetSection::after{
  content: "";
  height: 100%;
  width: 100%;
  background: radial-gradient(rgba(28, 28, 28, .0) 25%, rgba(28, 28, 28, 1.0) 67%, rgba(28, 28, 28, 1.0));
  grid-area: image;
  z-index: 25;
}


.GrindsetTextPart{
  width: 388px;
  grid-area: text;
  z-index: 100;
}

.GrindsetTextPart ul{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 0 0 20px;
}

.EmbraceSigmaGrindsetImage{
  width: min(100%,360px);
}

.SigmaGrindsetBackgroundImage{
  width: 100%;
  grid-area: image;
}

.SigmaGrindsetMobileImage{
  display: none;
}

@media (max-width:1000px) {

  .GrindsetSection{
    grid-template-columns: [text-start] auto [text-end image-start] auto [image-end];
    grid-template-rows: [text-start] [image-start] auto [text-end] [image-end];
    justify-content: space-evenly;
  }

  .GrindsetSection::after{
    content: none;
  }

  .SigmaGrindsetBackgroundImage{
    display: none;
  }

  .SigmaGrindsetMobileImage{
    display: block;
    grid-area: image;
    max-height: 380px;
    border: 6.5px black solid;
  }
}


@media (max-width:750px) {
  .GrindsetSection{
    align-items: center;
    justify-items: center;
    grid-template-columns: [image-start] auto [image-end];
    grid-template-rows: [image-start] auto [image-end];
  }

  .GrindsetTextPart{
    display: contents;
  }

  .GrindsetHeader{
    margin-top: 1.55rem;
  }
}

/* Testimonials section */
.TestimonialsList{
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: max(3.14%,1.2rem);
  /* 2*16=700*(x/100)+y*16, 4.25*16 = 1400*(x/100) + y*16 */
  gap: clamp(2rem, 5.143vw - 0.25rem, 4.25rem);
}

.TestimonialsList li{
  background-color: #000;
  width: min(100%,365px);
  border-radius: 20px;
  padding: 27px;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 337px;
}

.DoubleQuoteImage{
  width: 35px;
}

.TinyMarginTop{
  margin-top: 11px;
}

.AvatarImage{
  width: 75px;
}

.StarsImage{
  width: 105px;
}

.TestimonialsList h4{
  font-size: 0.95rem;
}

.AvatarPart{
  padding-top: 25px;
  display: flex;
  align-items: center;
  gap: 17px;
  border-top: 3px solid #171717;
}

.NameAndStarsPart{
  display: flex;
  flex-direction: column;
  gap: 6px;
}
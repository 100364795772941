/* repeated from tree, refactor */
.FadedBackground {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2700;
    background-color: rgba(0, 0, 0, 0.7);
}

.ModalContentSection {
    /* need to make responsive */
    position: fixed;
    inset: 0;
    width: 1000px;
    max-height: 90%;
    height: 510px;
    z-index: 3000;
    margin: auto;
    overflow: hidden;

    border-radius: 40px;

    background-color: #1c1c1c;

    /* other stuff */
    display: flex;
    align-items: center;
    padding-right: 80px;
}

.LightImage {
    height: 65%;
    margin: auto;
}

.TextPart {
    width: 528px;
    display: flex;
    flex-direction: column;
}

/* repeated with tree modal refactor */
.SpaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.starsImage {
    width: 150px;
    transform: translateX(18.5px);
}

.LongTextImage {
    width: 110%;
    align-self: center;
}

.ModalMainHeader {
    font-size: 33px;
}

.BackHeader {
    font-size: 16px;
    cursor: pointer;
}

.DescriptionText {
    font-size: 23px;
    font-weight: 500;
}

/* repeated, refactor */
@media (max-width:1100px) {
    .ModalContentSection {
        position: static;
        width: auto;
        height: auto;
        padding: 0;
        border-radius: 0;

        margin-inline: max(5%, 50% - 922px/2);
        align-items: center;

        overflow: unset;

        flex-direction: column;
    }

    .FadedBackground {
        display: none;
    }

    .LightImage {
        width: 60%;
        height: auto;
        margin-top: 1.8rem;
    }

    .TextPart {
        width: auto;
        margin-top: 1.8rem;
    }

    .ModalMainHeader {
        font-size: 1.55rem;
    }

    .BackHeader {
        font-size: 0.97rem;
    }

    .DescriptionText {
        font-size: 1.15rem;
    }

    .starsImage {
        width: 100px;
        transform: translateX(11.5px);
    }

    .DescriptionPart {
        margin-top: 0.5rem;
    }

    .LongTextImage {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
    }
}
.MainSection {
  display: grid;
  grid-template-columns: [background-start] max(3.3335%, 50% - 1765px/2) [sparkly-start] 0.375fr 0.25fr 0.25fr 0.25fr 0.5fr [sparkly-end] max(3.3335%, 50% - 1765px/2) [background-end];
  grid-template-rows: [background-start sparkly-start] auto [background-end sparkly-end];
  grid-template-areas: '... globe knight chair cat flagtree ...';
  overflow: hidden;
  align-items: flex-start;
  position: relative;
}

.RoundtableBackgroundImage {
  grid-area: background;
  width: 100%;
}

.SparklyLightsImage {
  grid-area: sparkly;
  width: 102.5%;
  z-index: 2000;
  pointer-events: none; 
  margin-left: 0.5%;
}

/* this is because we have to apply the styles to the a tag rather than the inner img tag since we hover sibling selector */
.MainSection a>img {
  width: 100%;
}

.Light {
  position: absolute;
}

/* Globe part */
.GlobeImage {
  grid-area: globe;
  width: 90%;
  align-self: center;
  justify-self: end;
  margin-right: -6%;
  margin-top: -12%;
  z-index: 50;
}

.LightOnGlobeImage {
  grid-area: globe;
  width: 128%;
  margin-top: -82%;
  margin-left: -2.2%;
  z-index: 100;
}

.GlobeImage:hover~.LightOnGlobeImage {
  opacity: 1;
}

/* globe part end */

/* Knight part */
.KnightImage {
  grid-area: knight;
  width: 122%;
  align-self: flex-end;
  margin-bottom: 14%;
  margin-left: -12%;
  z-index: 300;
}

.LightOnKnightImage {
  grid-area: knight;
  width: 197.5%;
  margin-top: -39.8%;
  margin-left: -50.4%;
  z-index: 250;
}

.KnightImage:hover~.LightOnKnightImage {
  opacity: 1;
}

/* knight part end */

/* Chair part */
.ChairImage {
  grid-area: chair;
  width: 97.8%;
  margin-top: 23%;
  margin-left: -12.8%;
  z-index: 50;
}

.LightOnChairImage {
  grid-area: chair;
  width: 170%;
  margin-top: -120%;
  margin-left: -46.4%;
  z-index: 100;
}

.ChairImage:hover~.LightOnChairImage {
  opacity: 1;
}

/* chair part end */

/* cat part */
.CatImage {
  grid-area: cat;
  width: 105%;
  margin-top: 32%;
  margin-left: -0.5%;
  align-self: center;
  z-index: 50;
}

.LightOnCatImage {
  grid-area: cat;
  width: 180%;
  margin-top: -65%;
  margin-left: -36%;
  z-index: 100;
}

.CatImage:hover~.LightOnCatImage {
  opacity: 1;
}

/* cat part end */

/* flag part */
.FlagImage {
  grid-area: flagtree;
  width: 73%;
  margin-top: 14%;
  z-index: 50;
}

.LightOnFlagImage {
  grid-area: flagtree;
  width: 97.5%;
  margin-top: -72.4%;
  margin-left: -11.5%;
  z-index: 100;
}

.FlagImage:hover~.LightOnFlagImage {
  opacity: 1;
}

/* flag part end */

/* Tree part */
.TreeImage {
  grid-area: flagtree;
  width: 69.8%;
  margin-top: 43%;
  z-index: 150;
  align-self: center;
  justify-self: flex-end;
}

.LightOnTreeImage {
  grid-area: flagtree;
  width: 108%;
  margin-top: -52%;
  margin-left: 12%;
  z-index: 100;
}

.TreeImage:hover~.LightOnTreeImage {
  opacity: 1;
}

/* tree part end */


/* repeated with tree, refactor */
.HoverImage,
.Light {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.HoverImage:hover {
  opacity: 1;
}

/* end repeated */

/* put this here to override the above z-indexes */
.HoverImage {
  z-index: 1600;
}

.ChairHover {
  z-index: 275;
}

.FlagHover {
  z-index: 125;
}

/* modal classes */
.GlobeModalDescription {
  color: #00B5E2;
}

.KnightModalDescription {
  color: #EC9D00;
}

.ChairModalDescription {
  color: #EE6800;
}

.CatModalDescription {
  color: #E70031;
}

.FlagModalDescription {
  color: #A348C5;
}

.TreeModalDescription {
  color: #93BD00
}

.MobileRoundtableBackgroundImage {
  display: none;
}

@media (max-width:1100px) {
  .RoundtableBackgroundImage {
    display: none;
  }

  .SparklyLightsImage {
    display: none;
  }

  .MobileRoundtableBackgroundImage {
    display: block;
    width: 100%;
    grid-area: background;
  }

  .MainSection {
    grid-template-columns: [background-start] max(3.3%, 50% - 922px/2) 1fr 1fr max(3.3%, 50% - 922px/2) [background-end];
    grid-template-rows: [background-start] 0.31fr 1.187fr 0.95fr 1.05fr 0.15fr [background-end];
    grid-template-areas: '... ... ... ...'
                         '... knight globe ...'
                         '... cat chair ...'
                         '... tree flag ...'
                         '... ... ... ...';
  }

  /* possibly repeated, refactor later */
  .GlobeImage {
    width: 102.8%;
    margin: 0;
    margin-left: -15%;
    justify-self: flex-start;
    align-self: flex-start;
  }
    
  .KnightImage {
    width: 93%;
    margin: 0;
    margin-right: -12%;
    justify-self: flex-end;
  }
    
  .ChairImage {
    width: 73%;
    margin: 0;
    margin-top: -56%;
    margin-left: 0%;
  }
    
  .CatImage {
    width: 78.4%;
    margin: 0;
    margin-right: -5.5%;
    margin-bottom: 5%;
    justify-self: flex-end;
    align-self: flex-end;
  }
  
  .FlagImage {
    grid-area: flag;
    width: 107.4%;
    margin: 0;
    margin-top: -53.5%;
    margin-left: -16%;
  }
  
  .TreeImage {
    grid-area: tree;
    width: 94%;
    margin: 0;
    margin-right: -14%;
    margin-bottom: -2%;
    align-self: flex-end;
  }

  .Light {
    display: none;
  }

  .HoverImage:hover {
    opacity: 0;
  }

  /* repeated, refactor */
  .HideOnMobile {
    display: none;
  }
}
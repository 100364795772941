.CopyrightText {
    font-size: 0.85em;
    color: rgba(255, 255, 255, 0.8)
}

.FooterContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 145px;
}

/*Basically a duplicate from header, possible refactor*/
.Navbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Navbar a {
    text-decoration: none;
    display: contents;
}

.Text {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
}

.Text:hover {
    color: #FFC41B;
    border-bottom: 1px solid  #FFC41B;
    margin-bottom: -1px;
}

.Menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1.7em;
}

.Menu li {
    display: flex;
    align-items: center; 
}

.SocialIcon {
    width: 21px;
}

.HoverIcon {
    position: absolute;
    opacity: 0;
}

.HoverIcon:hover {
    opacity: 1;
}
/*end duplicate part*/

@media (max-width:970px) {
    .Navbar {
        display: none;
    }

    .FooterContainer {
        height: 65px;
    }
}
.BlurBackground {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
}

.ModalContentSection {
    /* need to make responsive */
    position: fixed;
    inset: 0;
    width: 1000px;
    max-height: 90%;
    height: 510px;
    z-index: 2500;
    margin: auto;
    overflow: hidden;

    border-radius: 40px;

    /* repeated, refactor */
    background-image: url("./TreeBackground.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;

    /* other stuff */
    padding: 33px 85px 45px 85px;
    display: flex;
    flex-direction: column;
    gap: 27px;
}

.HeaderPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 6px solid #000000;
}

.ModalMainHeader {
    font-size: 50px;
}

.BackHeader {
    margin-right: 19px;
    font-size: 19px;
    cursor: pointer;
}

/* description part */
.DescriptionPart {
    flex: 1;
    display: flex;
    gap: 40px;
}

.DescriptionText {
    font-size: 14.5px;
    font-weight: 500;
}

.fullImage {
    width: 370px;
    object-fit: cover;
    border: 6px solid #000000;
    cursor: pointer;
}

/* zoomed image part sorta repeated refactor*/
.FadedBackground {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2700;
    background-color: rgba(0, 0, 0, 0.5);
}

.ZoomedImage {
    /* need to make responsive */
    position: fixed;
    inset: 0;
    width: 1000px;
    max-height: 90%;
    z-index: 3000;
    margin: auto;

    object-fit: cover;
    border: 6px solid #000000;
}

@media (max-width:1100px) {
    .ModalContentSection {
        position: static;
        width: auto;
        height: auto;
        background: none;
        padding: 0;
        border-radius: 0;
        
        margin-inline: max(5%, 50% - 922px/2);
        align-items: center;
        gap: 0;
    }

    .BlurBackground{
        display: none;
    }

    .HeaderPart{
        display: contents;
    }

    .DescriptionPart{
        display: contents;
    }

    .fullImage{
        width: 100%;
        margin-top: 1rem;
    }

    .BackHeader {
        margin: 0;
        align-self: flex-end;
        font-size: 1.31rem;
        margin-top: 1rem;
    }

    .ModalMainHeader{
        order: 1;
        font-size: 2.37rem;
        margin-top: 2rem;
    }

    .DescriptionText{
        order: 2;
        font-size: 1rem;
        margin-top: 1rem;
    }
}
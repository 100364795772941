/* Sigma Tree Section */
.SigmaTreeSection {
  display: grid;
}

.SigmaTreeImage {
  width: 100%;
  grid-area: 1/-1;
}

.GetPastSecurityImage {
  grid-area: 1/-1;
  width: 22%;
  min-width: 160px;
  align-self: end;
  justify-self: start;
  /* 23.5=700*(-x/100)+y, 0 = 1400*(-x/100) + y */
  transform: translateY(clamp(0px, -3.357vw + 47px, 23.5px));
  margin-left: clamp(-47.1719px, -6.31vw + 41.172px, -3px);
}

/* repeated again, refactor */
.HoverImage {
  opacity: 0;
  z-index: 300;
  position: absolute;
}

.HoverImage:hover {
  opacity: 1;
}

/* end repeated */

.HoverImage:hover~.UnderImage {
  opacity: 0;
}

/* Subscribe Section */
.SubscribeSection {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  border-radius: 8.5px;
  padding: 2rem 1.25rem;
  gap: 1.25rem;
}

.SigmaTreeSection a,
.TeamAndPitchSection a {
  display: contents;
}

.SubscribeInput,
.SubscribeButton {
  background: transparent;
  padding: clamp(8px, 0.286vw + 6px, 10px);
  border: 2px solid #FFC41B;
  font-size: clamp(1rem, 0.229vw + 0.9rem, 1.1rem);
}

.SubscribeInput {
  outline: none;
  color: rgba(255, 255, 255, 0.2)
}

.SubscribeButton {
  margin-left: -2px;
  color: #fff;
  padding-inline: clamp(10px, 0.357vw + 7.5px, 12.5px);
  transition: .3s;
  cursor: pointer;
}

.SubscribeButton:hover {
  background-color: #FFC41B;
  color: #000;
}

@media (max-width:1135px) {
  .SubscribeInput {
    width: 21ch;
  }
}

@media (max-width:432px) {
  .SubscribeInputAndButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
  }

  .SubscribeInput {
    border-width: 1px;
  }

  .SubscribeButton {
    margin-left: 0;
    background-color: #FFC41B;
    color: #000;
    border-radius: 7px;
    padding-inline: 22px;
  }
}

/* Team Part */
/* possible refactor */
.TeamList {
  list-style-type: none;
  display: grid;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  gap: 1.9rem;
  grid-template-columns: repeat(auto-fill, min(128px, 27.5%));
  min-width: 300px;
  flex: 1;
  justify-content: center;
}

.TeamMemberImage {
  width: 100%;
}

.TeamMemberName {
  margin-top: 0.7rem;
}

.TeamMemberRole {
  color: #F6CC31;
  margin-top: 0.3rem;
}

/* Pitch part */
.PitchPart {
  margin-top: -0.55rem;
  flex: 1;
}

.RoundtableLinkImage {
  width: 200px;
}

.TrailerLinkImage {
  width: 285px;
}

.SigmaverseLinkImage {
  width: 300px;
  transform: translateY(-18.4px);
}

.TrailerSigmaverseLinksContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

/* Team and Pitch Section */
.TeamAndPitchSection {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: max(5%, 2.5rem);
}

/*spinner*/
.Spinner {
  position: relative;
  color: rgba(255, 255, 255, 0);
}

@keyframes Spinner {
  to {
    transform: rotate(360deg);
  }
}

.Spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  border-right: 2px solid transparent;
  animation: Spinner .6s linear infinite;
}

/*end spinner*/

/* all the different hover image styles here */
.GetPastSecurityImageHover {
  position: static;
  transform: translateY(clamp(-0.5px, -3.357vw + 46.7px, 23.2px));
}

.RoundtableLinkImageHover {
  transform: translateY(-11px);
  width: 205.5px;
}

.TrailerLinkImageHover {
  width: 294.2px;
}

.SigmaverseLinkImageHover {
  width: 300.76px;
}
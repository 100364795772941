.TreeBackgroundImage {
  background-size: 100%;
  background-position: center;
}

@media (min-width:1101px) {
  .TreeBackgroundImage {
    background-image: url("./TreeBackground.jpg");
    background-repeat: no-repeat;
  }
}

@media (max-width:1100px) {
  .TreeBackgroundImage {
    background-image: url("./MobileTreeBackground.jpg");
    background-repeat: repeat;
  }
}

.MainSection {
  margin-inline: max(5%, 50% - 1350px/2);
  display: grid;
  grid-template-columns: [lines-start] 1fr 2.55fr [lines-end] 1fr;
  grid-template-rows: 0.063fr 0.25fr 0.25fr [lines-start] 0.5fr 0.5fr 1fr [lines-end] 0.25fr;
  grid-template-areas: '... ... ...'
    '... title training'
    'lounge tree training'
    'lounge tree hall'
    '... tree hall'
    'recovery tree seed'
    '... button ...';
}

.MainSection a {
  display: contents;
}

.SigmaTreeImage {
  width: 100%;
  grid-area: tree;
  margin-top: -0.5%;
}

.TreeTitle {
  /* 40=700*(x/100)+y, 80 = 1500*(x/100) + y */
  font-size: clamp(40px, 5vw + 5px, 80px);
  grid-area: title;
}

.ZoneHeading {
  /* 13=700*(x/100)+y, 26 = 1500*(x/100) + y */
  font-size: clamp(13px, 1.625vw + 1.625px, 26px);
}

/* refactor this shit */
.ZoneImage {
  width: 100%;
  grid-area: 1/1;
}

.MobileImage {
  display: none;
}

.HoverImage {
  opacity: 0;
  z-index: 1600;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
}

.HoverImage:hover {
  opacity: 1;
}

/* end refactor */

/* Zones */
.ZonePart {
  display: grid;
  row-gap: 7%;
  align-content: flex-start;
}

.LoungePart {
  grid-area: lounge;
  width: 118.7%;
  margin-top: -5.5%;
}

.RecoveryPart {
  grid-area: recovery;
  width: 118.7%;
  margin-top: -3%;
  margin-left: -2%;
}

.SeedPart {
  grid-area: seed;
  width: 118.7%;
  margin-left: -16%;
  margin-top: 9.2%;
}

.HallPart {
  grid-area: hall;
  width: 108.8%;
  margin-left: -10%;
  margin-top: -3.7%;
  row-gap: 1.6%;
}

.TrainingPart {
  margin-left: -21%;
  margin-top: -0.3%;
  width: 55%;
  grid-area: training;
}

.TrainingHeading {
  /* 14=1100*(x/100)+y, 19 = 1500*(x/100) + y */
  font-size: clamp(14px, 1.25vw + 0.25px, 19px);
}

/* Lines */
.LinesImage {
  width: 97%;
  grid-area: lines;
  margin-left: 7.5%;
  margin-top: 7.4%;
}

/* Mountain */
.MountainImage {
  grid-area: training;
  width: 40%;
  margin-left: 55%;
  margin-top: 10%;
  opacity: 0.7;
}

.JoinButton {
  border: none;
  grid-area: button;
  justify-self: center;
  width: 24%;
  height: 55%;
  /* refactor */
  /* 14=1100*(x/100)+y, 19 = 1500*(x/100) + y */
  font-size: clamp(14px, 1.25vw + 0.25px, 19px);
  border-radius: 5px;
  background-color: #000000;
  cursor: pointer;
}

/* Extras for zone modals, should prolly refactor the absolute */
.HallExtraImage {
  width: 330px;
  position: absolute;
  bottom: 25px
}

.TrainingExtraImage {
  width: 350px;
  position: absolute;
  bottom: 40px;
  right: 120px;
  opacity: 0.8;
}

.SeedExtraImage {
  width: 258px;
  position: absolute;
  bottom: -22px;
  right: 170px;
}

.RecoveryExtrasPart{
  display: contents;
}

.RecoveryExtraLeftImage {
  width: 250px;
  position: absolute;
  bottom: 14px;
  left: 60px;
}

.RecoveryExtraRightImage {
  width: 150px;
  height: 250px;
  object-fit: cover;
  object-position: left;
  position: absolute;
  bottom: -12px;
  left: 326px;
}

.LoungeExtrasPart{
  display: contents;
}

.LoungeExtraLeftImage {
  width: 127px;
  position: absolute;
  bottom: 25px;
  left: 70px;
}

.LoungeExtraRightImage {
  width: 345px;
  position: absolute;
  bottom: 30px;
  left: 182px;
}

@media (max-width:1100px) {

  .MainSection {
    margin-inline: max(5%, 50% - 922px/2);
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto auto auto auto;
    grid-template-areas: 'title''tree''button''lounge''recovery''hall''seed''training';
    row-gap: 2rem;
    margin-top: 1.25rem;
    margin-bottom: 2rem;
  }

  .LinesImage {
    display: none;
  }

  .ZoneImage {
    display: none;
  }

  .MobileImage {
    display: block;
    width: 100%;
  }

  .ZonePart {
    row-gap: 1rem;
    width: 100%;
    margin: 0;
  }

  .TreeTitle {
    /* 2.6*16=450*(x/100)+y*16, 4.5*16 = 1024*(x/100) + y*16 */
    font-size: clamp(2.6rem, 5.296vw + 1.11rem, 4.5rem);
  }

  .SigmaTreeImage {
    margin-top: -1.5rem;
  }

  .ZoneHeading {
    /* 1.5*16=450*(x/100)+y*16, 2.6*16 = 1024*(x/100) + y*16 */
    font-size: clamp(1.5rem, 3.066vw + 0.638rem, 2.6rem);
  }

  .JoinButton {
    width: auto;
    height: auto;
    /* 1.25*16=450*(x/100)+y*16, 1.9*16 = 1024*(x/100) + y*16 */
    font-size: clamp(1.25rem, 1.812vw + 0.74rem, 1.9rem);
    /*First value: 7=450*(x/100)+y, 12 = 1024*(x/100) + y */
    /*Second value: 15=450*(x/100)+y, 25 = 1024*(x/100) + y */
    padding: clamp(7px, 0.871vw + 3.08px, 12px) clamp(15px, 1.742vw + 7.16px, 25px);
    /* 5=450*(x/100)+y, 8.8 = 1024*(x/100) + y */
    border-radius: clamp(5px, 0.662vw + 2.021px, 8.8px);
  }

  .MountainImage {
    grid-area: tree;
    width: 15%;
    align-self: start;
    justify-self: end;
    margin: -0.875rem 0 0 0;
  }

  .HideOnMobile {
    display: none;
  }

  /* Extras for different zones */
  /* big refactor needed */
  .SeedExtraImage {
    width: 100%;
    position: static;
    order: 5;
    margin-top: 2rem;
    margin-left: -7.5px;
  }

  .TrainingExtraImage {
    position: static;
    order: 5;
    margin-top: 2rem;
  }

  .HallExtraImage{
    position: static;
    order: 5;
    margin-top: 2rem;
  }

  .LoungeExtrasPart{
    display: flex;
    align-items: center;
    order: 5;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .LoungeExtraLeftImage{
    position: static;
    width: 30%;
  }

  .LoungeExtraRightImage{
    position: static;
    width: 70%;
  }

  .RecoveryExtrasPart{
    display: flex;
    align-items: center;
    order: 5;
  }

  .RecoveryExtraLeftImage{
    position: static;
    width: 60%;
  }

  .RecoveryExtraRightImage{
    position: static;
    width: 40%;
  }
}
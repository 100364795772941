.MintingDetailsSection {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: [image-start] auto 585px clamp(42px, 50.735vw - 581.03px, 180px) min(360px, 24%) auto [image-end];
  grid-template-rows: [image-start] auto [image-end];
  grid-template-areas: '... text ... video ...';
}

.MultiSigmaCatsBackgroundImage {
  width: 100%;
  min-height: 382px;
  object-fit: cover;
  grid-area: image;
}

.MintingDetailsSection::after {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, rgba(28, 28, 28, 1.0), rgba(28, 28, 28, 0.8) 10%, rgba(28, 28, 28, 0.8));
  grid-area: image;
}

.MintingDetailsTextPart {
  grid-area: text;
  z-index: 100;
  background-color: rgba(28, 28, 28, 0.9);
  padding: min(1.67vw, 25px) min(3vw, 45px);
  border-radius: 40px;
}

.MultiSigmaCatsReelVideo {
  grid-area: video;
  width: 100%;
  z-index: 100;
}

@media (max-width:1160px) {
  .MintingDetailsSection {
    grid-template-rows: [image-start] auto [image-end] auto;
    grid-template-columns: [image-start] max(5%, 50% - 1275px/2) auto max(5%, 50% - 1275px/2) [image-end];
    grid-template-areas: '... video ...''... text ...';
    row-gap: max(5%, 2.5rem);
  }

  .MintingDetailsTextPart {
    padding: 0;
  }

  .MultiSigmaCatsReelVideo {
    width: 70%;
    max-height: 300px;
  }
}

.BudgetImagesContainer {
  display: flex;
  margin-top: max(3.7%, 2.5rem);
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: max(4%, 2.5rem);
}

.BudgetAllocationsPieImage {
  width: min(375px, 70%);
}

.LineDivider {
  margin-left: 0.2px;
  margin-right: 0.25px;
  width: 1.5px;
  height: 385.09px;
  background-color: #D9D9D9;
}

@media (max-width:1306px) {
  .LineDivider {
    display: none;
  }
}

/* hack for the line thingy */
@media (min-width: 1245px) and (max-width:1306px) {
  .BudgetImagesContainer {
    padding-inline: 30px;
  }
}

.FaqItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.FaqItem {
  overflow: hidden;
  background: #ffffff;
  width: min(620px, 100%);
  border-radius: 20px;
  color: #2D2D2D;
}

.FaqItem summary {
  /* 1.1*16=700*(x/100)+y*16, 1.3*16 = 1400*(x/100) + y*16 */
  font-size: clamp(1.1rem, 0.457vw + 0.9rem, 1.3rem);
  display: flex;
  cursor: pointer;
  padding: 0.87rem 2.175rem;
  background: #ffc41b;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
}

.QuestionText {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.FaqItem[open] .QuestionText {
  text-overflow: unset;
  overflow: unset;
  white-space: unset;
}

.FaqItem>summary::marker,
/* Latest Chrome, Edge, Firefox */
.FaqItem>summary::-webkit-details-marker

/* Safari */
  {
  display: none;
}

.FaqItem p {
  padding: 1.3em 2.2em;
  text-align: left;
}

.Arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  padding: 3px;
  transition: all 0.3s ease;
}

.FaqItem[open] .Arrow {
  transform: rotateZ(45deg);
}

/* styles for the colors of the budget allocations key */
/* why is budget allocations key and nft distribution so weird? 
to make it the same as the images in the previous design */

/* refactor */
/* this is to get the top and bottom padding as a percentage */
.BudgetAllocationsKeyContainer {
  width: min(320px, 85%);
  aspect-ratio: 406/519;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BudgetAllocationsKeyList {
  list-style-type: none;
  padding: 0;

  width: 100%;
  height: 98.5%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: left;
  font-size: min(3.862vw - 0.03638rem, 0.975rem);
  white-space: nowrap;
}

.BudgetAllocationsKeyItem {
  display: flex;
  align-items: center;
  gap: 14.9%;
}

.SmallBox {
  width: 9.3%;
  height: 127%;
}

.BoxBlue {
  background-color: #0070c0;
}

.BoxRiver {
  background-color: #445469;
}

.BoxRed {
  background-color: #bd392f;
}

.BoxOrange {
  background-color: #f39b26;
}

.BoxLime {
  background-color: #9bbb5c;
}

.BoxJade {
  background-color: #1ea185;
}

.BoxPurple {
  background-color: #6f3d79;
}

/* the nft distrution styles */
/* repeated, refactor */
.NftDistributionList{
  list-style-type: none;
  padding: 0;
  width: min(338px, 87%);
  aspect-ratio: 427/513;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: left;
  font-size: min(3.862vw - 0.03638rem, 0.975rem);
}

.NftDistributionList li:nth-child(2){
  margin-top: -1.5%;
}

.NftDistributionItem{
  display: flex;
  gap: 2.5%;
}

.NftDistributionHeader{
  font-size: min(4.8552vw - 0.04645rem, 1.225rem);
}
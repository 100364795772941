.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 7.5%;
}

.Navbar a {
    text-decoration: none;
    display: contents;
}

.Text {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
}

.Text:hover {
    color: #FFC41B;
    border-bottom: 1px solid #FFC41B;
    margin-bottom: -1px;
}

.Links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1.7em;
}

.MobileHeaderSigmaCat {
    display: none;
}

.Links li,
.LogoContainer {
    display: flex;
    align-items: center;
}

.Logo {
    width: 55px;
    z-index: 1500;
}

.SocialIcon {
    width: 21px;
}

.HoverIcon {
    position: absolute;
    opacity: 0;
    z-index: 1600;
}

.HoverIcon:hover {
    opacity: 1;
}

.Menu {
    display: flex;
    gap: 1.7rem;
}

.FadedBackground {
    display: none;
}

@media (max-width:970px) {

    .SocialIcon {
        width: 35px;
    }

    .Text {
        font-size: 1.25em;
    }

    /* Mobile Hamburger Icon */
    .MobileHamburger {
        z-index: 1500;
        width: 30px;
        height: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        cursor: pointer;
        gap: 5.5px;
    }

    .Line {
        width: 25px;
        height: 2.7px;
        background-color: #fff;
        border-radius: 1.5px;
        transition: transform .3192s .1s cubic-bezier(0.04, 0.04, 0.12, 0.96);
    }

    .Active .LineTop {
        transform: translateY(8.305px) rotate(45deg);
    }

    .LineMiddle {
        width: 19px;
        opacity: 1;
        animation: fadeIn .3192s ease-in;
    }

    .Active .LineMiddle {
        opacity: 0;
        animation: fadeOut .3192s ease-in;
    }

    .Active .LineBottom {
        transform: translateY(-8.305px) rotate(-45deg);
    }

    /* End mobile Hamburger */

    .Header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 5000;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(2rem);
        height: 65px;

        transition: height .3s cubic-bezier(0.32, 0.08, 0.24, 1);
    }

    .Header+div {
        margin-top: 65px;
    }

    .Header.Active {
        height: 737.13px;
    }

    .Menu {
        flex-direction: column;
        align-items: center;
        padding-top: 13.4px;
        gap: 2.6rem;

        position: fixed;
        width: 100%;
        height: 0;
        top: 65px;
        left: 0;
        opacity: 0;
        visibility: hidden;
    }

    .Active .Menu {
        height: min(671.8px, calc(100vh - 65px));
        height: min(671.8px, calc(100dvh - 65px));
        opacity: 1;
        visibility: visible;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .NavLinks {
        flex-direction: column;
        align-items: center;
    }

    .Links {
        gap: 2.5rem;
    }

    .MobileHeaderSigmaCat {
        margin-top: 0.1rem;
        display: block;
        width: 275px;
        transform: translateX(62.5px)
    }

    .Active .MobileHeaderSigmaCat,
    .Active .Menu li {
        animation: fadeIn 0.4s ease-in;
    }

    /* Keyframe animations */
    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    /*repeated refactor*/
    .Header.Active .FadedBackground {
        display: block;
        position: fixed;
        top: 500px;
        width: 100%;
        height: calc(100vh - 500px);
        z-index: -1;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, .5) 237.13px, rgba(0, 0, 0, .5) 100%);
        animation: fadeIn 0.25s ease-in;
    }
}